@import "./../../assets/styles/variables/colors.scss";
@import "./../../assets/styles/variables/fonts.scss";
@import "./../../assets/styles/mixins/media.scss";

.ground {
  display: flex;
  border-bottom: 1px solid #E6E6E6;

  .logo {
    height: 7.875rem;
    width: 15rem;
  }

  .sidebar {
    flex: 1;
    display: flex;
    flex-direction: column;
    border-left: 1px solid #E6E6E6;


    .topField {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #E6E6E6;

      .infoBlock {
        display: flex;
        align-items: center;

        .info {
          display: flex;
          gap: 0.75rem;
          padding: 0.5rem 4.5rem;
          border-right: 1px solid #E6E6E6;
          margin: 0.5rem;

          &:last-child {
            border: none;
          }

          .title {
            color: #5E5E5E;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin: 0;
            padding: 0;
          }
        }
      }

      .changeLang {
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-right: 2rem;

        .btn {
          width: 3rem;
          height: 2rem;
          object-fit: cover;
          border-radius: 6px;
          box-shadow: 0 0 1px 1px $gray;
          cursor: pointer;

          &:hover {
            box-shadow: 0 0 1px 1.5px $gray300;

          }
        }

        .uzb {
          width: 2rem;
          height: 2rem;
          object-fit: cover;
          border-radius: 50%;
        }
      }
    }

    .bottomField {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .navigation {
        display: flex;
        align-items: center;
        gap: 4.25rem;
        list-style-type: none;
        padding: 0 4.25rem;
        margin: 0;


        &>li {
          display: flex;
          align-items: center;
          justify-content: center;
          color: #5E5E5E;
          height: 5.32rem;

          font-family: $fontOutfit;
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          .active {
            color: #0A1172;
            font-weight: 600;

          }

          &>a {
            color: $darkBlue;
            text-decoration: none !important;
          }
        }
      }

      .blog {
        width: 18.75rem;
        height: 5.32rem;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        color: #5E5E5E;
        border-left: 1px solid #E6E6E6;

        &.active {
          color: #0A1172;
          font-weight: 600;

        }
      }
    }
  }
}

// @include media-max("lg") {
//   .ground {
//     .logo {
//       height: 5.875rem;
//       width: 8rem;
//       margin: auto;
//     }

//     .topField {

//       .info {
//         padding: 0.5rem 2.5rem !important;
//       }

//       .btn {
//         width: 2rem !important;
//         height: 1.5rem !important;
//       }
//     }
//   }
// }