@import './../../assets/styles/variables/colors.scss';
@import "./../../assets/styles/mixins/media.scss";

.ground {
  display: flex;
  align-items: center;
  gap: 8.44rem;
  margin-top: 3.25rem;
  margin-bottom: 3.25rem;

  .leftSection {
    width: 33.625rem;
    display: flex;
    flex-direction: column;

    &>h1 {
      color: $navy;
      font-size: 4rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin: 0;
      margin-bottom: 1.25rem;
    }

    &>h2 {
      color: $darkBlue;
      font-size: 2rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin: 0;
      margin-bottom: 1.25rem;
    }

    &>p {
      color: $deepBlue;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0;
      margin-bottom: 3.38rem;
    }
  }

  .rightSection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .form {
      width: 35.3125rem;
      display: flex;
      flex-direction: column;

      &>label {
        color: $berry;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 0.94rem;
      }

      &>input {
        margin-bottom: 1.75rem;
        background: $lightGray;
        width: 100%;
        height: 2.875rem;
        border: none;
        border-radius: 2px;
        outline: none;
        color: $navy;
      }

      &>textarea {
        width: 100%;
        height: 10rem !important;
        margin-bottom: 1.75rem;
        background: $lightGray;
        width: 100%;
        height: 2.875rem;
        border: none;
        border-radius: 2px;
        outline: none;
        color: $navy;
        resize: none;
      }
    }

    .button {
      width: 100%;
      padding: 0.8125rem 15.375rem;
      color: $white;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      background: $navy;
      border: none;
      outline: none;
      border-radius: 2px;
    }
  }
}

@include media-max("lg") {
  .ground {
    flex-direction: column;
  }
}

@include media-max("md") {
  .leftSection {
    width: 20rem !important;
  }

  .form {
    width: 20rem !important;
    margin: auto;
  }

  .button {
    padding: 0.8125rem 4.375rem !important;
  }
}

.socialNetworks {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2.44rem;

  .icons {
    width: 2.5625rem;
    height: 2.5625rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $lightGray;
    border-radius: 50%;
    cursor: pointer;
  }
}