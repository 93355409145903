@import './../../assets/styles/variables/colors.scss';

.ground {
  margin-top: 4rem;
  margin-bottom: 8rem;

  .topBar {
    display: flex;
    align-items: center;
    gap: 3.5rem;
    margin-bottom: 4.81rem;

    .figure {
      width: 6.6875rem;
      height: 1.125rem;
      border-radius: 0.9375rem;
      border: 2px solid $berry;
    }

    &>h1 {
      margin: 0;
      color: $berry;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

  .content {
    display: flex;
    align-items: flex-start;
    gap: 1.25rem;

    .header {
      width: 58.875rem;
      position: relative;
      z-index: 2;

      color: $darkBlue;
      font-size: 3rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin: 0;

      &>span {
        position: absolute;
        left: -6rem;
        top: 8rem;
      }
    }

    .infoField {
      display: flex;
      flex-direction: column;
      gap: 8rem;

      .info {
        display: flex;
        align-items: flex-start;
        gap: 2.27rem;

        &>p {
          width: 25rem;
          color: $nightRider;
          font-size: 1.5rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin: 0;
        }

        &>img {
          margin-top: 1.22rem;
        }
      }
    }
  }
}