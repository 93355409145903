@import './../../assets/styles/variables/colors.scss';

.ground {
  height: 26.25rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 8rem;

  .leftSection {
    width: 27.8125rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    &>h1 {
      width: 26.625rem;
      color: $nightRider;
      font-size: 1.75rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin: 0;
      margin-bottom: 1.56rem;
    }

    &>p {
      color: $gray300;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0;
      margin-bottom: 4rem;
    }

    .link {
      color: $nightRider;
    }
  }

  .rightSection {
    width: 47.6875rem;
    height: 100%;
    background: $navy;

    &>h1 {
      color: $mercuryGray;
      font-size: 1.875rem;
      font-style: italic;
      font-weight: 400;
      line-height: normal;
      margin: 4.81rem 8.75rem 4.13rem;
    }

    .bottomField {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 2.19rem;
      padding-left: 6.5rem;
      padding-right: 2.56rem;

      .userImage {
        width: 3.688rem;
        height: 3.688rem;
        object-fit: cover;
        border-radius: 50%;
        border: 4px solid $white;
      }

      &>span {
        flex: 1;

        .name {
          color: $white;
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          margin: 0;
          margin-bottom: 0.5rem;
        }

        .position {
          color: $white;
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin: 0;
        }
      }
    }
  }
}