@import './../../assets/styles/variables/colors.scss';

.ground {
  width: 17.9375rem;
  height: 21.8125rem;
  border-bottom: 5px solid $navy;
  background: $lightGray;
  display: flex;
  flex-direction: column;
  padding-top: 2.94rem;
  padding-left: 2.19rem;

  .iconField {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 4.125rem;
    height: 4.125rem;
    background: $nightRider;
    border-radius: 50%;
    margin-bottom: 8.56rem;

    .icon {
      width: 2rem;
      height: 2rem;
    }
  }

  .title {
    color: $nightRider;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
    margin-bottom: 0.75rem;
  }
}