@import "./../../assets/styles/variables/colors.scss";
@import "./../../assets/styles/variables/fonts.scss";

.button {
  outline: none;
  box-shadow: none;

  padding: 1rem 3.5rem;
  max-width: 20rem;

  text-align: center;
  font-family: $fontOutfit;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: none;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  flex-shrink: 0;

  background: inherit;
  border: 1px solid $white;
  color: $white;
  cursor: pointer;

  &:hover {
    color: $white;
  }

  &.dark {
    background: inherit;
    color: $berry;
    border: 1px solid $berry;
  }
}