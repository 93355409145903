@import './../../assets/styles/variables/colors.scss';
@import "./../../assets/styles/variables/fonts.scss";
@import "./../../assets/styles/mixins/media.scss";

.ground {

  .numberSection {
    background-image: url('./../../assets/images/back_image.png');
    width: 100%;
    height: 10.4375rem;
    z-index: -1;

    .numberField {
      position: absolute;
      right: 15rem;
      width: 33.6rem;
      height: 10.4375rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background: $white;
      padding-left: 8rem;
      z-index: 2;

      .callUs {
        color: $nightRider;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin: 0;
        margin-bottom: 0.5rem;
      }

      .phone {
        color: $navy;
        font-size: 2.625rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin: 0;
      }

    }
  }
}

//Styles for top section
.topSection {
  width: 100%;
  height: 46.125rem;
  flex-shrink: 0;
  background: url('./../../assets/images/dashboard.png') no-repeat center $nightRider;
  object-fit: contain;

  .topSectionContent {
    height: 46.125rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;


    &>h1 {
      width: 39.0625rem;
      color: $white;
      font-size: 2.625rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin: 0;
      margin-bottom: 1.5rem;
    }

    &>h2 {
      color: $white;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin: 0;
      margin-bottom: 1rem;
    }

    &>p {
      width: 26.75rem;
      color: $white;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 3.5rem;
    }

    .button {
      width: 13rem;
    }
  }
}

//Styles for About Section
.aboutSection {
  width: 100%;
  height: 21rem;
  background: $lightGray;
  display: flex;
  align-items: center;

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &>span>h1 {
      color: $nightRider;
      font-size: 1.75rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin: 0;
      margin-bottom: 1.37rem;
    }

    &>span>p {
      width: 34.375rem;
      color: $gray300;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

//Styles for Help Section
.helpSection {
  max-width: 1440px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 6.25rem;
  padding: 9rem 0;
  margin: auto;

  .leftBlock {
    width: 35rem;

    .title {
      color: $black;
      text-align: right;
      font-size: 2.625rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 2.81rem;
    }

    .imgField {
      width: 35rem;
      height: 22.0625rem;

      .image {
        width: 35rem;
        height: 22.0625rem;
        object-fit: cover;
      }
    }
  }

  .rightBlock {
    width: 50rem;
    height: 36.6875rem;
    flex-shrink: 0;
    background: $navy;
    padding: 11rem 0 0 6rem;

    &>h1 {
      width: 19.75rem;
      color: $white;
      font-size: 2.625rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin: 0;
      margin-bottom: 1.62rem;
    }

    &>p {
      width: 38.6875rem;
      color: $white;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0;
      margin-bottom: 3.56rem;
    }
  }
}

//Styles for Industries Section
.industries {
  margin-bottom: 8rem;

  .title {
    width: 35rem;
    color: $nightRider;
    font-size: 2.625rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
    margin-bottom: 5rem;
  }

  .cardsField {
    display: flex;
    flex-wrap: wrap;
    gap: 3.5rem 3rem;

    .seeAll {
      width: 17.9375rem;
      height: 21.8125rem;
      display: flex;
      align-items: center;
      justify-content: center;

      .link {
        color: $nightRider;
      }
    }
  }
}

@include media-max("xxl") {
  .leftBlock {
    display: none;
  }
}

@include media("xxxl") {
  .helpSection {
    max-width: 1920px;
    margin: auto;

    .rightBlock {
      width: 60rem
    }
  }
}