@import './../../assets/styles/variables/colors.scss';

.ground {
  width: 100%;
  background: $nightRider;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 3.8rem 3.38rem;

  //Styles logo section
  .logoSection {
    display: flex;
    flex-direction: column;
    align-items: center;

    &>img {
      width: 12.6875rem;
      margin-bottom: 2.19rem;
    }
  }

  //Styles Links section
  .linkSection {
    width: 17.375rem;
    margin-left: 6.25rem;
    margin-right: 3.44rem;

    .itemsField {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      list-style-type: none;
      padding: 0;

      .list {
        color: $white;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        &>a {
          text-decoration: none;
          color: $white;
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }

  //Styles Services section
  .serviceSection {
    width: 17.375rem;
    margin-right: 3.44rem;

    .itemsField {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      list-style-type: none;
      padding: 0;

      .list {
        color: $white;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        &>a {
          text-decoration: none;
          color: $white;
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }

  //Styles Sertificates section
  .sertificateSection {
    width: 17.375rem;
    margin-right: 3.44rem;
  }

  //Styles Right section
  .rightSection {

    .socialNetworks {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-bottom: 2.44rem;

      .icons {
        width: 2.5625rem;
        height: 2.5625rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $white;
        border-radius: 50%;
        cursor: pointer;
      }
    }

    .infoField {
      display: flex;
      flex-direction: column;
      list-style-type: none;
      gap: 2rem;
      padding: 0;
      padding-left: 1.69rem;

      &>li {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        color: $white;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }
}

.header {
  color: $white;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  margin-bottom: 2.5rem;
}

.bottomGround {
  background: $berry;
  width: 100%;
  padding: 1rem 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title {
    color: $white;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
  }

  .rightBlock {
    display: flex;
    align-items: center;
    gap: 1.5rem;

    .header {
      color: $white;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin: 0;
    }
  }
}