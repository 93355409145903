@import "./../../assets/styles/variables/colors.scss";
@import "./../../assets/styles/variables/fonts.scss";

.button {
  display: flex;
  align-items: center;

  color: $azure;
  cursor: pointer;

  text-decoration: none;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;

  .icon {
    width: 16px;
    height: 16px;
  }
}