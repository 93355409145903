.app {
  --navy: #0A1172;
  --berry: #241571;
  --azure: #1520A6;
  --darkBlue: #0A2640;
  --lightGray: #F5F5F5;
  --white: #FFFFFF;
  --black: #000000;
  --gray: #777;
  --gray900: #0B072A;
  --gray800: #454156;
  --gray600: #7B8096;
  --gray400: #9499B5;
  --gray300: #5e5e5e;
  --gray200: #DBE3EA;
  --gray100: #EFF3F5;
  --mercuryGray: #E6E6E6;
  --nightRider: #2E2E2E;
  --orange: #FF4D00;
  --deepBlue: rgba(10, 38, 64, 0.80);

}

$navy: var(--navy);
$berry: var(--berry);
$azure: var(--azure);
$darkBlue: var(--darkBlue);
$lightGray: var(--lightGray);
$white: var(--white);
$black: var(--black);
$gray: var(--gray);
$gray900: var(--gray900);
$gray800: var(--gray800);
$gray600: var(--gray600);
$gray400: var(--gray400);
$gray300: var(--gray300);
$gray200: var(--gray200);
$gray100: var(--gray100);
$mercuryGray: var(--mercuryGray);
$deepBlue: var(--deepBlue);
$orange: var(--orange);
$nightRider: var(--nightRider);