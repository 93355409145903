@import './../../assets/styles/variables/colors.scss';

.ground {

  .topSection {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem 3rem;
    margin-top: 2rem;
    margin-bottom: 4rem;

    .servicePreview {
      width: 18rem;
      background: $lightGray;
      display: flex;
      align-items: center;
      gap: 1rem;
      border-bottom: 5px solid $darkBlue;
      cursor: pointer;
      padding: 0.5rem;

      &.active {
        border-color: $orange;
      }

      &>span {
        width: 4.125rem;
        height: 4.125rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $gray300;
        border-radius: 50%;
      }

      &>h1 {
        color: $nightRider;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin: 0;
      }
    }
  }

  .content {
    margin-bottom: 8rem;

    .contentField {
      &>h1 {
        font-size: 4rem;
        font-style: normal;
        font-weight: 400;
        line-height: 5.25rem;
        text-align: center;
        margin: 0;
        margin-bottom: 2rem;
      }

      .description {
        margin: 0;
        font-size: 2.25rem;
        font-style: normal;
        font-weight: 400;
        line-height: 3rem;
        text-align: center;
        margin-bottom: 2rem;
      }

      &>p {
        margin: 0;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2rem;

      }
    }
  }
}